import { useState, useEffect } from 'react';
import { TextField, InputLabel, Select } from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { CustomTable } from '../../Components/Table/CustomTable';
import { ReportMainInfo } from '../../Components/ReportMainInfo/ReportMainInfo';
import config from '../../config/config';

import classes from './Reports.module.css';

const { tranchePrefix, organizationPrefix } = config;

export const ReportWithCurrLimit = () => {
	const axiosPrivate = useAxiosPrivate();

	const [reportData, setReportData] = useState([]);
	const [currDate, setCurrDate] = useState(new Date());
	const [dateReport, setDateReport] = useState(new Date());
	const [loading, setLoading] = useState(false);
	const [orgList, setOrgList] = useState([]); //список всех организаций
	const [orgId, setOrgId] = useState('1'); // id выбранной организации
	const [currentOrg, setCurrentOrg] = useState();
	const [filteredData, setFilteredData] = useState([]);
	const [totalLimit, setTotalLimit] = useState();
	//вычисления

	let currentBalance = filteredData?.reduce(
		(accumulator, currentValue) => accumulator + currentValue.balance,
		0
	);

	let currentPlannedRepay = filteredData?.reduce(
		(accumulator, currentValue) => accumulator + currentValue.totalPlannedRepay,
		0
	);

	let balanceOfLimit =
		Number(totalLimit) - Number(currentBalance) + Number(currentPlannedRepay);
	//вычисления

	const filterData = (array) => {
		let newArr;
		const currDate2 = new Date(currDate);
		newArr = array.map((tranche) => {
			let transactionBefore = tranche.transaction.filter(
				(item) => new Date(item.date) < dateReport
			);
			let transactionAfter = tranche.transaction.filter(
				(item) =>
					new Date(item.date) > dateReport && new Date(item.date) > currDate2
			);
			return {
				...tranche,
				transaction: [...transactionBefore, ...transactionAfter],
			};
		});

		return newArr;
	};

	const filterDataTransaction = (array) => {
		let newArr;

		const currDate2 = new Date(currDate);

		let transactionBefore = array.filter(
			(item) => new Date(item.date) < dateReport
		);
		let transactionAfter = array.filter(
			(item) =>
				new Date(item.date) > dateReport && new Date(item.date) > currDate2
		);
		newArr = [...transactionBefore, ...transactionAfter];

		return newArr;
	};

	const handleFilter = () => {
		let data;
		data =
			orgId === '1'
				? reportData
				: reportData.filter((el) => el.org._id === orgId);

		const res = data.map((el) => {
			const plannedSum = filterDataTransaction(el.transaction).reduce(
				(accumulator, currentValue) => accumulator + currentValue.plannedRepay,
				0
			);
			let sum = 0;
			for (let item of el.transaction) {
				if (
					new Date(item.date) <= new Date(currDate) &&
					new Date(item.date) > new Date(dateReport)
				) {
					sum += item.plannedRepay;
				}
			}

			let currBalance = parseFloat((el.balance - sum).toFixed(2));

			return { ...el, totalPlannedRepay: plannedSum, balance: currBalance };
		});

		setCurrentOrg(orgList.find((el) => el._id === orgId));
		setTotalLimit(orgList.find((el) => el._id === orgId)?.limit);
		setFilteredData(filterData(res));
	};

	useEffect(() => {
		// async function getReport() {
		// 	try {
		// 		setLoading(true);
		// 		const { data } = await axiosPrivate.get(`${tranchePrefix}/orders`);
		// 		setLoading(false);

		// 		const dataWithRepay = data
		// 			.map((el) => {
		// 				const plannedSum = el.transaction.reduce(
		// 					(accumulator, currentValue) =>
		// 						accumulator + currentValue.plannedRepay,
		// 					0
		// 				);
		// 				return { ...el, totalPlannedRepay: plannedSum };
		// 			})
		// 			.filter((el) => el.balance !== 0);
		// 		setReportData(dataWithRepay);
		// 		setFilteredData(dataWithRepay);
		// 		// setDateReport(currDate);
		// 		// setCurrentOrg(response.data.data);
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// }
		async function getData() {
			try {
				setLoading(true);
				const { data } = await axiosPrivate.get(`${tranchePrefix}/orders`);
				const org = await axiosPrivate.get(`${organizationPrefix}/complete`);
				setLoading(false);
				//org

				let set = new Set();
				for (let item of data) {
					set.add(item.org._id);
				}
				const currentOrg = org?.data?.filter((el) => set.has(el._id));
				let totalLimit = currentOrg.reduce(
					(accumulator, currentValue) => accumulator + currentValue.limit,
					0
				);
				currentOrg.unshift({
					_id: '1',
					limit: totalLimit,
					name: 'Все',
				});
				//org

				//reportDate
				const dataWithRepay = data
					.map((el) => {
						const plannedSum = el.transaction.reduce(
							(accumulator, currentValue) =>
								accumulator + currentValue.plannedRepay,
							0
						);
						return { ...el, totalPlannedRepay: plannedSum };
					})
					.filter((el) => el.balance !== 0);
				//reportDate
				setReportData(dataWithRepay);
				setFilteredData(dataWithRepay);
				setOrgList(currentOrg);
				setTotalLimit(totalLimit);
			} catch (error) {
				console.log(error);
			}
		}
		getData();
		// getReport();
	}, [axiosPrivate]);
	return (
		<main className={classes.wrapper}>
			<h1>Отчеты</h1>

			<div className={classes.dataPickerWrapper}>
				<div>
					<InputLabel shrink>Организация</InputLabel>
					<Select
						native
						sx={{
							minWidth: '200px',
						}}
						value={orgId}
						defaultValue={'1'}
						name="org"
						onChange={(event) => setOrgId(event.target.value)}
					>
						{orgList?.map((item) => (
							<option key={item?._id} value={item?._id} name={item?.name}>
								{item?.name}
							</option>
						))}
					</Select>
				</div>
				<div>
					<InputLabel shrink>От</InputLabel>
					<TextField
						fullWidth
						name="dateReport"
						sx={{ width: '200px', height: '100%' }}
						variant="outlined"
						onChange={(event) => {
							const { value } = event.target;
							setCurrDate(
								value ? new Date(value).toISOString().split('T')[0] : value
							);
						}}
						value={currDate}
						autoComplete="off"
						type="date"
					/>
				</div>

				<button onClick={handleFilter}>Показать отчет</button>
				{/* {message && <span style={{ color: 'red' }}>{message}</span>} */}
			</div>
			{/* {trancheList.map(tranche)} */}
			<h2>Отчет по: {currentOrg?.name} </h2>
			<section>
				<ReportMainInfo
					date={dateReport}
					limit={totalLimit}
					balance={currentBalance}
					plannedRepay={currentPlannedRepay}
					balanceOfLimit={balanceOfLimit}
				/>
				<div className={classes.table}>
					<CustomTable data={filteredData} totalLimit={totalLimit} />
				</div>
			</section>
		</main>
	);
};
