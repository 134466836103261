import { TableItem } from './TableItem/TableItem';
import { tableHeader } from '../../utils/tableHeader';

export const CustomTable = ({ data, totalLimit }) => {
	let limit = totalLimit;
	const sortedData = data
		.sort((a, b) => {
			return (
				new Date(a.returnTranche).getTime() -
				new Date(b.returnTranche).getTime()
			);
		})
		.map((el) => {
			limit = parseFloat((limit - el.balance).toFixed(2));
			return {
				...el,
				excess: limit,
			};
		});

	return (
		<table border="3" cellPadding="10" style={{ borderCollapse: 'collapse' }}>
			{/* <caption>Отчеты</caption> */}
			<thead>
				<tr>
					{tableHeader.map((el) => (
						<th key={el}>{el}</th>
					))}
				</tr>
			</thead>
			{/* <tfoot>
				<tr>
					<td colspan="6">Итого</td>
					<td>118</td>
				</tr>
			</tfoot> */}
			<tbody>
				{sortedData?.map((el) => (
					<TableItem
						name={el.org?.name}
						date={el.date}
						creditForm={el.creditForm.name}
						balance={el.balance}
						trancheDay={el.trancheDay}
						returnTranche={el.returnTranche}
						transaction={el.transaction}
						excess={el.excess}
						// id={el._id}
						key={el._id}
					/>
				))}
			</tbody>
		</table>
	);
};
