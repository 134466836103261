export const tableHeader = [
	'Организация',
	'Дата взятия долга',
	'Форма долга',
	'Текущий долг',
	'Планируемая Σ к оплате',
	'Срок погашения',
	'Погасить до',
	'Остаток лимита',
];

export const tableHeaderTransaction = [
	'Фактическая оплата',
	'Сумма(факт)',
	'Сумма(план)',
	'Дата операции',
];
