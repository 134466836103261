import { useState } from 'react';
import { tableHeaderTransaction } from '../../../utils/tableHeader';
import {
	changeDateFormat,
	changeCurrencyFormat,
} from '../../../utils/changeFormat';

export const TableItem = ({
	name,
	date,
	creditForm,
	balance,
	trancheDay,
	returnTranche,
	transaction,
	excess,
}) => {
	const [open, setOpen] = useState(false);
	let totalPlannedRepay = transaction.reduce(
		(accumulator, currentValue) => accumulator + currentValue.plannedRepay,
		0
	);
	const sortedTransaction = transaction.sort((a, b) => {
		return new Date(a.date).getTime() - new Date(b.date).getTime();
	});

	return (
		<>
			<tr align="center" bgColor={excess < 0 ? '#ff9d9d' : ''}>
				<td>{name}</td>
				<td>{changeDateFormat(date)}</td>
				<td>{creditForm}</td>
				<td>{changeCurrencyFormat(balance)}</td>
				<td>{changeCurrencyFormat(totalPlannedRepay)}</td>
				<td>{trancheDay}</td>
				<td>{changeDateFormat(returnTranche)}</td>
				<td>{changeCurrencyFormat(excess)}</td>
				<td>
					{!!sortedTransaction.length && (
						<button onClick={() => setOpen(!open)}>Подробнее</button>
					)}
				</td>
			</tr>
			{!!sortedTransaction.length && (
				<tr style={{ display: open ? '' : 'none' }}>
					<td colSpan="9" align="right" style={{ border: '3px solid teal' }}>
						<table
							border="1"
							cellPadding="5"
							width="90%"
							style={{ borderCollapse: 'collapse' }}
						>
							<thead>
								<tr>
									{tableHeaderTransaction.map((el) => (
										<th key={el}>{el}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{sortedTransaction.map((el) => (
									<tr
										key={el._id}
										align="center"
										bgColor={
											!el.real
												? new Date(el.date).getTime() < Date.now()
													? '#ff9d9d'
													: 'yellow'
												: ''
										}
									>
										<td>{el.real ? '+' : '-'}</td>
										<td>{changeCurrencyFormat(el.repay)}</td>
										<td>{changeCurrencyFormat(el.plannedRepay)}</td>
										<td>{changeDateFormat(el.date)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</td>
				</tr>
			)}
		</>
	);
};
