import { NavLink } from 'react-router-dom';
import classes from './NotFound.module.css';

export const NotFound = () => {
	return (
		<div className={classes.wrapper}>
			<h1>404 Страница на найдена</h1>
			<NavLink to={'/'}>на главную</NavLink>
		</div>
	);
};
